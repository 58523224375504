// TranslateWith.Wiki - A web app to translate terms using Wikipedia
// language links

// Copyright (C) 2022 Marien Raat - mail@marienraat.nl

// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.

// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.

// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
@import 'colors';

.article-possibilities {
    .react-list-select {
	display: inline-block;
	position: relative;
	outline: none;
    }

    .react-list-select--item {
	cursor: pointer;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
    }

    .react-list-select--item.is-selected {
	-webkit-user-select: inherit;
	-moz-user-select: inherit;
	-ms-user-select: inherit;
	-o-user-select: inherit;
	user-select: inherit;
    }

    .react-list-select--item.is-focused {
	-webkit-user-select: inherit;
	-moz-user-select: inherit;
	-ms-user-select: inherit;
	-o-user-select: inherit;
	user-select: inherit;
    }

    .is-focused {
        .list-group-item {
            background-color: $light-accent;
        }
    }

    .is-selected {
        .list-group-item {
            background-color: $primary;
            color: white;

            .badge {
                background-color: white !important;
                color: black;
            }
        }
    }

    .badge {
        margin-left: 0.25rem;
    }

    .react-list-select--item.is-disabled {
	cursor: default;
    }

    ul {
	list-style: none;
	padding: 0;
	margin: 0;
    }

    li {
        padding: 0px;
        margin: 0px;
    }
    .float-right {
        float: right;
    }

    .snippet {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .list-group-item {
        padding: 8px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    @media (max-width: 1200px) {
        .card-body {
            max-height: 170px;
            overflow-y: auto;
        }
    }

    .searchmatch {
        font-weight: bold;
    }

    .vert {
        display: flex;
        flex-direction: row;
        align-items: left;
        max-width: 100%;
    }

    .textdiv {
        flex-grow: 1;
        min-width: 0;
    }

    .imgsizer {
        max-height: 100%;
        min-width: 58px;
        margin-right: 0.5rem;
    }
}
