// TranslateWith.Wiki - A web app to translate terms using Wikipedia
// language links

// Copyright (C) 2022 Marien Raat - mail@marienraat.nl

// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.

// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.

// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

@import "colored-bootstrap";

.card-header {
    background-color: $light-accent;
}
    
body {
    background-color: $page-bg;
    height: 100vh;
    margin: 0;

    .App {
        height: 100vh;
        margin: 0;
        display: flex;
        flex-direction: column;

        .footer-container {
            width: 100%;
            margin-top: auto;

            footer {
                background-color: #46174d;
                color: #bbb;
                margin-top: 1em;
                padding: 0.8em;
                text-align: center;
                width: 100%;

                a {
                    color: #fff;
                }
            }
        }
    }
}

.navbar {
    background-color: $navbar-bg;
}

